<template>
  <div>
    <a-drawer :title="`${form.id ? '编辑' : '新建'}套餐`" :width="720" :visible="visible"
      :body-style="{ paddingBottom: '80px' }" :footer-style="{ textAlign: 'right' }" @close="onClose">
      <a-form :model="form" :rules="rules" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="名称" name="name">
              <a-input v-model:value="form.name" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="政策详情" name="policy_detail">
              <a-input v-model:value="form.policy_detail" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="政策匹配" name="policy_mate">
              <a-input v-model:value="form.policy_mate" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="专利查询" name="patent_inquiry">
              <a-input v-model:value="form.patent_inquiry" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="ai 机器人" name="ai">
              <a-input v-model:value="form.ai" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="子账户数量" name="sub_count">
              <a-input v-model:value="form.sub_count" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="赠送积分" name="integral">
              <a-input v-model:value="form.integral" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="套餐价格" name="price">
              <a-input v-model:value="form.price" placeholder="请输入" />
            </a-form-item>
          </a-col>




  





          <!-- <a-col :span="12">
            <a-form-item label="分类说明" name="description">
              <a-input v-model:value="form.description" placeholder="请输入" />
            </a-form-item>
          </a-col> -->
        </a-row>
      </a-form>
      <template #extra>
        <a-space>
          <a-button @click="onClose">取消</a-button>
          <a-button :loading="createLoading" type="primary" @click="onConfirm">提交</a-button>
        </a-space>
      </template>
    </a-drawer>
  </div>
</template>
<script>
//

import service from '@/service/service';
//import UploadFile from '@/components/common/uploadFile.vue';
import { message } from 'ant-design-vue';
const rules = {
  name: [{
    required: true,
    message: '请输入分类名称',
  }],
  sort_num: [{
    required: true,
    message: '请输入分类序号',
  }],
};
export default {
  components: {
     //UploadFile
  },
  props: ['current'],
  data() {
    return {
      rules,
      form: {
        id: '',
        name: '',
        policy_detail:'',
        policy_mate: '',
        patent_inquiry: '',
        ai: '',
        sub_count:0,
        integral: '',
        price: 0,
      },
      
      visible: false,
      createLoading: false,
    }
  },
  watch: {
    current: {
      handler(nowCurrent) {
        if (nowCurrent) {
          let temp = {};
          for (let key in this.form) {
            temp[key] = nowCurrent[key];
          }
          this.form = temp;
          // console.log("bbbbbb")
          // console.log(this.configData)
        }
      },
      immediate: true
    }
  },
  methods: {
    onClose() {
      this.visible = false;
      this.onReset();
      this.$emit('close');
    },
    onReset() {
      let temp = {};
      for (let key in this.form) {
        temp[key] = '';
      }
      this.form = temp;
    },
    onShow() {
      this.visible = true;
    },
    async onConfirm() {
      this.createLoading = true;
      console.log(this.form);

      const api = this.form.id ? 'edit_package' : 'add_package';
      const { code, msg } = await service[api]({
        ...this.form
      })
      this.createLoading = false;
      if (code && code == '1') {
        message.success(msg || '成功');
        this.$emit('refreshList');
        this.onClose();
      }
    }
  }
}
</script>